import { useEffect } from 'react';
import VendablePreviewView from '../../vendables/preview/VendablePreviewView';
import { useParams } from 'react-router-dom';

export default function HoldingPreview() {
  const { vendableId, holdingId } = useParams();

  useEffect(() => {
    const redirectUrl = `/products/preview/${vendableId}/register?holdingId=${holdingId}`;
    localStorage.setItem('redirectUrl', redirectUrl);
  }, [holdingId]);

  return (
    <div className="flex w-full flex-col pt-[120px]">
      <div className="w-full grow px-4 pb-[70px] pt-8 md:m-[0_auto] md:max-w-[1024px] md:scroll-px-28 lg:py-[25px] lg:px-0 lg:pb-12">
        <VendablePreviewView vendableId={vendableId} />
      </div>
    </div>
  );
}
